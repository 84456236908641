import React from 'react';

const IconLogo = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 84 96">
    <path
      style={{ fill: '#01122b', stroke: '#67fdda', strokeMiterlimit: '10', strokeWidth: '2px' }}
      d="m81,26.58v43.83c0,.36-.2.7-.52.88l-37.97,21.42c-.32.18-.7.18-1.02-.01L3.5,70.3c-.31-.19-.5-.52-.5-.88V25.59c0-.36.2-.7.52-.88L41.49,3.29c.32-.18.7-.18,1.02.01l37.99,22.4c.31.19.5.52.5.88Z"
    />
    <path
      fill="#67fdda"
      d="m46.4,63.52l-6.85-12.1h-2.94v12.1h-6.23v-31.04h11.65c2.4,0,4.45.42,6.14,1.27s2.96,1.99,3.8,3.42c.85,1.44,1.27,3.05,1.27,4.83,0,2.05-.59,3.89-1.78,5.54s-2.95,2.78-5.29,3.4l7.43,12.59h-7.21Zm-9.79-16.77h5.2c1.69,0,2.95-.41,3.78-1.22.83-.81,1.25-1.95,1.25-3.4s-.42-2.53-1.25-3.31c-.83-.79-2.09-1.18-3.78-1.18h-5.2v9.12Z"
    />
  </svg>
);

export default IconLogo;
